// src/components/Profile.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <div id="user-profile">
        <h2>Welcome, {user.name}</h2>
      </div>
    )
  );
};

export default Profile;
