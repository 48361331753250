// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth0ProviderWithHistory from "./taskpane/components/Auth0ProviderWithHistory";
import Auth from "./taskpane/components/Auth";
import Profile from "./taskpane/components/Profile";
import EmailAnalyzer from "./taskpane/components/EmailAnalyzer";

const App = () => {
  return (
    <Router>
      <Auth0ProviderWithHistory>
        <div className="app-container">
          <Auth />
          <Profile />
          <EmailAnalyzer />
        </div>
      </Auth0ProviderWithHistory>
    </Router>
  );
};

export default App;
