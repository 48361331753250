// src/components/EmailAnalyzer.js
import React, { useState } from 'react';
import ResultsDisplay from './ResultsDisplay';

const EmailAnalyzer = () => {
  const [result, setResult] = useState(null);

  const analyzeEmail = async () => {
    try {
      // Fetch email body
      const bodyResult = await new Promise((resolve, reject) => {
        Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, (result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            resolve(result);
          } else {
            reject(new Error("Failed to read email body"));
          }
        });
      });
  
      const headerResult = await new Promise((resolve, reject) => {
        Office.context.mailbox.item.getAllInternetHeadersAsync((result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            resolve(result);
          } else {
            reject(new Error("Failed to read email headers"));
          }
        });
      });
  
      const emailBody = bodyResult.value;
      const emailHeaders = headerResult.value;
      const userEmail = Office.context.mailbox.userProfile.emailAddress;
      const displayName = Office.context.mailbox.userProfile.displayName;
      const fromName = Office.context.mailbox.item.from;
      const senderEmail = Office.context.mailbox.item.sender;
      const fromEmailAddress = fromName.emailAddress;
      const fromDisplayName = fromName.displayName;
      const fromRecipientType = fromName.recipientType;
      const senderEmailEmailAddress = senderEmail.emailAddress;
      const senderEmailDisplayName = senderEmail.displayName;
      const senderEmailRecipientType = senderEmail.recipientType;

      const response = await fetch("https://react.whatthephish.ai/worker", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "f01ae9931b744aa1b914eee5a0d5f77b",
        },
        body: JSON.stringify({
          emailHeaders: emailHeaders,
          emailBody: emailBody,
          userEmailAddress: userEmail,
          displayName: displayName,
          fromEmailAddress: fromEmailAddress,
          fromDisplayName: fromDisplayName,
          fromRecipientType: fromRecipientType,
          senderEmailEmailAddress: senderEmailEmailAddress,
          senderEmailDisplayName: senderEmailDisplayName,
          senderEmailRecipientType: senderEmailRecipientType,
        }),
      });

      if (!response.ok) throw new Error("Failed to get response from the server");

      const data = await response.json();
      setResult(data);
    } catch (error) {
      setResult({ classification: "Error", certainty: "", details: error.message });
    }
  };

  return (
    <div>
      <button onClick={analyzeEmail}>Analyze Email</button>
      {result && <ResultsDisplay result={result} />}
    </div>
  );
};

export default EmailAnalyzer;
