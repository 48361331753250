// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Wait for Office to initialize before rendering the app
Office.onReady((info) => {
  if (info.host === Office.HostType.Outlook) {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);
  } else {
    console.error("This application is only supported within Outlook.");
  }
});
