// src/components/ResultsDisplay.js
import React from 'react';

const ResultsDisplay = ({ result }) => (
  <div id="result-display">
    <div><strong>Classification:</strong> {result.classification}</div>
    <div><strong>Certainty:</strong> {result.certainty}</div>
    <div><strong>Details:</strong> {result.details}</div>
  </div>
);

export default ResultsDisplay;
